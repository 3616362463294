<template>
  <div
    id="panTiltVisualiser"
    class="panTiltVisualiser text-center"
  >
    <div id="container" />
    <div class="btn-group d-inline-block mt-2">
      <button
        v-if="directions.left == false"
        class="btn btn-secondary"
        @click="rotateCamera('left')"
      >
        left side
      </button>
      <button
        v-else
        class="btn btn-primary cursor-not-allowed"
        disabled
      >
        left side
      </button>
      <button
        v-if="directions.center == false"
        class="btn btn-secondary"
        @click="rotateCamera('center')"
      >
        from behind
      </button>
      <button
        v-else
        class="btn btn-primary cursor-not-allowed"
        disabled
      >
        from behind
      </button>
      <button
        v-if="directions.right == false"
        class="btn btn-secondary"
        @click="rotateCamera('right')"
      >
        right side
      </button>
      <button
        v-else
        class="btn btn-primary cursor-not-allowed"
        disabled
      >
        right side
      </button>
    </div>
  </div>
</template>

<script>
import * as Three from 'three'

export default {
  name: 'PanTiltVisualiser',
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cameraMesh: null,
      container: null,
      scene: null,
      hemiLight: null,
      dirLight: null,
      camera: null,
      sky: null,
      ground: null,
      renderer: null,
      pole: null,
      directions: {
        left: false,
        center: true,
        right: false
      }
    }
  },
  mounted () {
    this.tryToStart();
  },
  methods: {
    rotateCamera (direction) {
      if (direction.includes('left')) {
        this.directions.left = true;
        this.directions.right = false;
        this.directions.center = false;
        return;
      }
      if (direction.includes('right')) {
        this.directions.left = false;
        this.directions.right = true;
        this.directions.center = false;
        return;
      }
      if (direction.includes('center')) {
        this.directions.left = false;
        this.directions.right = false;
        this.directions.center = true;
        return;
      }
    },
    tryToStart () {
      if (!this.status || !this.status.currentPt) {
        setTimeout(() => {
          this.tryToStart();
        }, 10);
      } else {
        this.initialise();
        this.animate();
        window.addEventListener( 'resize', this.onWindowResize, false );
      }
    },
    onWindowResize () {
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
      this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
      this.camera.updateProjectionMatrix();
    },
    initialise () {
      // get canvas-container
      this.container = document.getElementById('container');

      // create the camera
      this.camera = new Three.PerspectiveCamera(75, this.container.clientWidth / this.container.clientHeight, 0.1, 1000);
      this.camera.position.x = 0;
      this.camera.position.y = 0.333;
      this.camera.position.z = 1;

      // create the scene
      this.scene = new Three.Scene();
      this.scene.background = new Three.Color().set('skyblue');
      this.scene.fog = new Three.Fog(this.scene.background, 1, 1250);
      this.scene.add(this.camera);

      // create the lights
      this.hemiLight = new Three.HemisphereLight( 0xffffff, 0xffffff, 1 );
      this.hemiLight.color.set('skyblue');
      this.hemiLight.groundColor.set('white');
      this.hemiLight.position.set( 0, 50, 0 );
      this.scene.add( this.hemiLight );
      
      this.dirLight = new Three.DirectionalLight( 0xffffff, 1 );
      this.dirLight.color.setHSL( 0.1, 1, 0.95 );
      this.dirLight.position.set( - 1, 1.75, 1 );
      this.dirLight.position.multiplyScalar( 30 );
      this.scene.add( this.dirLight );

      this.dirLight.castShadow = true;
      this.dirLight.shadow.mapSize.width = 2048;
      this.dirLight.shadow.mapSize.height = 2048;
      this.dirLight.shadow.camera.left = -50;
      this.dirLight.shadow.camera.right = 50;
      this.dirLight.shadow.camera.top = 50;
      this.dirLight.shadow.camera.bottom = -50;
      this.dirLight.shadow.camera.far = 300;
      this.dirLight.shadow.bias = - 0.0001;

      // create the ground
      let groundGeo = new Three.PlaneBufferGeometry( 10000, 10000 );
      let groundMat = new Three.MeshLambertMaterial( { color: 0xffffff } );
      groundMat.color.set('white');
      this.ground = new Three.Mesh( groundGeo, groundMat );
      this.ground.position.y = - 250;
      this.ground.rotation.x = - Math.PI / 2;
      this.ground.receiveShadow = true;
      this.scene.add( this.ground );

      // create the renderer
      this.renderer = new Three.WebGLRenderer({antialias: true});
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
      this.renderer.setPixelRatio( window.devicePixelRatio );
      this.renderer.shadowMap.enabled = true;
      this.container.appendChild(this.renderer.domElement);
      this.renderer.gammaInput = true;
      this.renderer.gammaOutput = true;
      this.renderer.shadowMap.enabled = true;

      // create the box
      let geometry = new Three.BoxGeometry(0.4, 0.4, 0.8);
      let boxMaterial = new Three.MeshPhongMaterial({
        color: 'gray',
        specular: 'gray',
        shininess: 50,
        flatShading: Three.SmoothShading
      });
      let boxMesh = new Three.Mesh(geometry, boxMaterial);
      boxMesh.castShadow = true;
      boxMesh.receiveShadow = true;
      this.cameraMesh = new Three.Group();
      this.cameraMesh.add(boxMesh);

      // create the roof
      let roofGeo = new Three.BoxGeometry(0.4, 0.025, 0.9);
      let roofMesh = new Three.Mesh(roofGeo, boxMaterial);
      roofMesh.castShadow = false;
      roofMesh.receiveShadow = true;
      roofMesh.position.y = 0.21;
      roofMesh.position.z = -0.05;
      this.cameraMesh.add(roofMesh);
      this.scene.add(this.cameraMesh);
      
      // create pole
      var poleGeo = new Three.CylinderGeometry( 0.075, 0.075, 20, 32 );
      this.pole = new Three.Mesh( poleGeo, boxMaterial );
      this.pole.position.y = -10;
      this.pole.position.z = 0.05;
      this.pole.castShadow = true;
      this.pole.receiveShadow = true;
      this.scene.add( this.pole );
      
      this.camera.lookAt( this.cameraMesh.position );
    },
    animate () {
      requestAnimationFrame(this.animate);
      let factor = Math.PI / 180;

      let yValue = this.status.currentPt.tilt * factor;
      let xValue = (this.status.currentPt.pan * factor) / -2;

      if (this.directions.left == true) {
        this.camera.position.x = -1;
        this.camera.position.z = 0;
      } else if (this.directions.right == true) {
        this.camera.position.x = 1;
        this.camera.position.z = 0;
      } else {
        this.camera.position.x = 0;
        this.camera.position.z = 1;
      }
      this.camera.lookAt( this.cameraMesh.position );

      this.cameraMesh.rotation.x = yValue;
      this.cameraMesh.rotation.y = xValue;
      this.renderer.render(this.scene, this.camera);
    }
  }
}
</script>

<style>
#container {
  width: 100%;
  height: 250px;
}
</style>
